import { createStore } from 'vuex'

export default createStore({
  state: {
    isLogged: localStorage.getItem('isLogged') || '0',
    user_id: localStorage.getItem('user_id') || null,
    token: localStorage.getItem('token') || '',
  },
  getters: {
    isLogged(state){
      return state.isLogged
    },
    user_id(state){
      return state.user_id
    },
    token(state){
      return state.token
    }
  },
  mutations: {
    set_id_logged(state, param){
      state.isLogged = param
      localStorage.setItem('isLogged', param)
    },
    set_token(state, token){
      state.token = token
      localStorage.setItem('token', token)
    },
    set_id(state, id){
      state.user_id = id
      localStorage.setItem('user_id', id)
    }

  },
  actions: {
    setIsLogged({commit}, param){
      commit('set_id_logged', param)
    },
    setToken({commit}, token){
      commit('set_token', token)
    },
    setId({commit}, id){
      commit('set_id', id)
    }
  },
  modules: {
  }
})
