// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";

// Vuetify
import { createVuetify } from "vuetify";
import { VDataTable } from "vuetify/labs/VDataTable";


export default createVuetify({
    components: {
      VDataTable
    },
    defaults: {
      VTextField: {
        variant: "outlined",
        rounded: "lg",
        class: 'pt-2'
      },
      VTextarea : {
        variant: "outlined",
        rounded: "lg",
        class: 'pt-2'
      }
    }
  }
);
