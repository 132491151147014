import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_app_bar_nav_icon = _resolveComponent("v-app-bar-nav-icon")!
  const _component_v_app_bar_title = _resolveComponent("v-app-bar-title")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, { app: "" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_app_bar, {
        app: "",
        color: "yellow-darken-3"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_app_bar_nav_icon, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.drawer=!_ctx.drawer)),
            class: "d-flex d-sm-none"
          }),
          _createVNode(_component_v_app_bar_title, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(this.orgName), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_v_container, { class: "d-none d-sm-flex" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, {
                onClick: _cache[1] || (_cache[1] = ($event: any) => (this.$router.push('/product')))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Продажа имущества")
                ]),
                _: 1
              }),
              _createVNode(_component_v_btn, {
                onClick: _cache[2] || (_cache[2] = ($event: any) => (this.$router.push('/trades')))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Торги")
                ]),
                _: 1
              }),
              _createVNode(_component_v_btn, {
                onClick: _cache[3] || (_cache[3] = ($event: any) => (this.$router.push('/docs')))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Документы")
                ]),
                _: 1
              }),
              _createVNode(_component_v_btn, {
                onClick: _cache[4] || (_cache[4] = ($event: any) => (this.$router.push('/feedback')))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Обратная связь")
                ]),
                _: 1
              }),
              _createVNode(_component_v_btn, {
                onClick: _cache[5] || (_cache[5] = ($event: any) => (this.$router.push('/about')))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Об организации")
                ]),
                _: 1
              }),
              (this.isLogged==1)
                ? (_openBlock(), _createBlock(_component_v_btn, {
                    key: 0,
                    to: "/settings"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Настройки")
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (this.isLogged==0)
                ? (_openBlock(), _createBlock(_component_v_btn, {
                    key: 1,
                    onClick: _cache[6] || (_cache[6] = ($event: any) => (this.$router.push('/login')))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Вход")
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (this.isLogged==1)
                ? (_openBlock(), _createBlock(_component_v_btn, {
                    key: 2,
                    onClick: _cache[7] || (_cache[7] = ($event: any) => (this.$router.push('/logout')))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Выход")
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_navigation_drawer, {
        modelValue: _ctx.drawer,
        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.drawer) = $event)),
        absolute: "",
        temporary: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_list, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_list_item, {
                "prepend-icon": "mdi-registered-trademark",
                to: "/product"
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Продажа имущества")
                ]),
                _: 1
              }),
              _createVNode(_component_v_list_item, {
                "prepend-icon": "mdi-salesforce",
                to: "/trades"
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Торги")
                ]),
                _: 1
              }),
              _createVNode(_component_v_list_item, {
                "prepend-icon": "mdi-file-document-multiple",
                to: "/docs"
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Документы")
                ]),
                _: 1
              }),
              _createVNode(_component_v_list_item, {
                "prepend-icon": "mdi-rss",
                to: "/feedback"
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Обратная связь")
                ]),
                _: 1
              }),
              _createVNode(_component_v_list_item, {
                "prepend-icon": "mdi-office-building",
                to: "/about"
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Об организации")
                ]),
                _: 1
              }),
              (this.isLogged==1)
                ? (_openBlock(), _createBlock(_component_v_list_item, {
                    key: 0,
                    "prepend-icon": "mdi-cog",
                    to: "/settings"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Настройки")
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (this.isLogged==0)
                ? (_openBlock(), _createBlock(_component_v_list_item, {
                    key: 1,
                    "prepend-icon": "mdi-login-variant",
                    to: "/login"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Вход")
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (this.isLogged==1)
                ? (_openBlock(), _createBlock(_component_v_list_item, {
                    key: 2,
                    "prepend-icon": "mdi-logout-variant",
                    to: "/logout"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Выход")
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_v_main, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_container, null, {
            default: _withCtx(() => [
              _createVNode(_component_router_view)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}