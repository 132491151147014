<template>

</template>

<script>
export default {
  name: "Home"
};
</script>

<style scoped>

</style>