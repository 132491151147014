import httpBackend from "@/http-backend";
import store from "@/store";

class OrgService {
  create(org: any): Promise<any>{
    httpBackend.defaults.headers.common.Authorization = "Bearer " + store.state.token
    return httpBackend.post('/org', org)
  }

  update(org: any): Promise<any>{
    httpBackend.defaults.headers.common.Authorization = "Bearer " + store.state.token
    return httpBackend.put('/org', org)
  }

  getOrg(){
    return httpBackend.get('/org')
  }

  sendFeedback(body: any): Promise<any>{
    return httpBackend.post('/org/feedback', body)
  }

}

export default new OrgService()