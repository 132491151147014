import axios, { AxiosInstance } from "axios";

const backendClient: AxiosInstance = axios.create({
  baseURL: "https://onyx-soft.ru:3000/",
  //baseURL: "http://127.0.0.1:3000/",
  headers: {
    "Content-Type": "application/json"
  }
});

export default backendClient